import { useEffect } from "react";
import { useAppStore } from "~/store";
import type { AccountSettings } from "~/store/settings";
import { cache } from "~/utils/cache";
import type { ParsedAccount } from "~/utils/hive";

export function useFillStore({
  activeAccount,
  settings
}: {
  activeAccount: ParsedAccount | null;
  settings: AccountSettings;
}) {
  const setActiveAccount = useAppStore(store => store.account.setActiveAccount);
  const setPremium = useAppStore(store => store.account.setPremium);
  const setSettings = useAppStore(store => store.settings.setSettings);

  useEffect(() => {
    if (typeof window === "undefined") return;

    if (activeAccount && typeof setActiveAccount === "function") {
      setActiveAccount(activeAccount);

      if (typeof setPremium === "function") {
        // void (async function () {
        //   const premium = await cache.getPremium(activeAccount.name);
        //   setPremium({
        //     ...(premium ? premium : activeAccount.premium),
        //     fetched: true
        //   });
        // })();
        setPremium({ ...activeAccount.premium, fetched: true });
      }
    }

    if (settings) {
      window.localStorage.setItem("account-settings", JSON.stringify(settings));

      if (typeof setSettings === "function") {
        setSettings(settings);
      }
    }
  }, [activeAccount, settings]);
}

