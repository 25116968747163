import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo } from "react";
import { useFloating, offset, flip, shift } from "@floating-ui/react";
import { useLocation } from "@remix-run/react";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import { useAppStore } from "~/store";
import PublishDropdownButtonContent from "~/components/general/PublishDropdownButtonContent";
import useScrollDirection from "~/hooks/useScrollDirection";
import { cn } from "~/utils/cn";

export function PublishButton() {
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const scrollDirection = useScrollDirection(200);
  const [isOpen, setIsOpen] = useState(false);
  const { x, y, reference, floating, strategy } = useFloating({
    middleware: [offset(10), flip(), shift()],
    placement: "top-end",
    strategy: "fixed"
  });

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  const publishRoute = useMemo(() => pathname === "/publish", [pathname]);
  const shortsRoute = useMemo(
    () => pathname?.startsWith("/shorts"),
    [pathname]
  );

  const communityNameForPublish = useMemo(() => {
    if (pathname?.startsWith("/communities/")) {
      return pathname.split("/")[2];
    }
    return undefined;
  }, [pathname]);

  if (publishRoute || shortsRoute || !activeAccount || !isMobile) return null;

  return (
    <div
      className={cn(
        "pc:hidden fixed bottom-20 right-4 z-[999] transition-transform duration-150",
        {
          "translate-y-10": scrollDirection === "down"
        }
      )}
    >
      <button
        ref={reference}
        onClick={handleClick}
        className="w-14 h-14 flex justify-center items-center rounded-full bg-acc text-pri"
      >
        <FontAwesomeIcon icon={faPen} size="lg" />
      </button>
      {isOpen && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0
          }}
          className="w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg"
        >
          <PublishDropdownButtonContent
            communityNameForPublish={communityNameForPublish}
            setIsOpen={setIsOpen}
          />
        </div>
      )}
    </div>
  );
}

