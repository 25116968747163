import { config } from "@fortawesome/fontawesome-svg-core";
import { json, type LinksFunction, type LoaderFunction } from "@remix-run/node";
import {
  LiveReload,
  Outlet,
  PrefetchPageLinks,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation } from
"@remix-run/react";
import { useMemo } from "react";
import styles from "./styles/app.css";
import tooltipStyles from "react-tooltip/dist/react-tooltip.css";
import fontawesomeStyles from "./styles/fortawesome.css";
import phoneInputStyles from "react-phone-input-2/lib/style.css";
import datepickerStyles from "react-datepicker/dist/react-datepicker.min.css";
import fontStyles from "./styles/fonts.css";
import extraCss from "./styles/extra.css";
import { cache } from "~/utils/cache";
import type { ParsedAccount } from "~/utils/hive";
import { getActiveAccount } from "~/session.server";
import "nprogress/nprogress.css?__remix_sideEffect__";
import { ToastContainer } from "react-toastify";
import toastStyles from "react-toastify/dist/ReactToastify.css";
import { leocache, SERVER_ADRESS } from "./utils/leocache";
import AppHeader from "./components/AppHeader";
import CustomErrorBoundary from "./components/ErrorBoundary";
import type { AccountSettings } from "./store/settings";
import { Head } from "./components/layout/Head";
import { Sidebar } from "./components/layout/Sidebar";
import { PublishButton } from "./components/layout/PublishButton";
import { Announcements } from "./components/layout/Announcements";
import { cn } from "./utils/cn";
import { useFillStore } from "./hooks/useFillStore";
import { useHandleRouteChange } from "./hooks/useHandleRouteChange";
import { useRouteClientLoader } from "./hooks/useRouteClientLoader";
import { isSSR } from "./utils/ssr";

config.autoAddCss = false;

export const loader: LoaderFunction = async ({ request }) => {
  const activeAccountName = await getActiveAccount(request);

  const isLoggedIn = activeAccountName !== null;

  if (isLoggedIn) {
    void async function () {
      try {
        fetch(`${SERVER_ADRESS}/warm_user_data/${activeAccountName}`);
      } catch {}
    }();
  }

  const [activeAccount, settings] = await (async () => {
    if (isLoggedIn) {
      return Promise.all([
      cache.getAccount(activeAccountName),
      leocache.getInfraSettings(activeAccountName)]
      );
    } else {
      return [null, null];
    }
  })();

  const userAgent = request.headers.get("user-agent");
  const isMobile = Boolean(
    userAgent?.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );

  const pathname = new URL(request.url).pathname;

  // Retrieve the theme from the cookies
  const theme = settings?.settings?.theme || null;

  const ref = new URL(request.url).searchParams.get("referral");
  const existingRef = request.headers.
  get("cookie")?.
  split("; ")?.
  find((cookie) => cookie?.startsWith("referral="))?.
  split("=")?.[1];

  return json(
    {
      env: {
        THREAD_NODE: "https://inleo.io/leocache"
      },
      activeAccount,
      settings: settings?.settings,
      theme,
      isMobile,
      pathname
    },
    {
      headers: {
        "Cache-Control": "private, max-age=120",
        ...(ref && !existingRef ?
        { "Set-Cookie": `referral=${ref}; path=/; max-age=30` } :
        {})
      }
    }
  );
};

interface RootData {
  env: object;
  activeAccount: ParsedAccount | null;
  settings: AccountSettings;
  theme: string;
  isMobile: boolean;
  pathname: string;
}

export const links: LinksFunction = () => {
  return [
  {
    rel: "manifest",
    href: "/manifest.json"
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/icons/apple-touch-icon.png"
  },
  {
    rel: "apple-touch-startup-image",
    href: "/icons/apple-launch-1125x2436.png"
  },
  {
    rel: "icon",
    href: "https://inleo.io/logo.svg",
    type: "image/svg"
  },
  { rel: "preload", href: styles, as: "style" },
  { rel: "stylesheet", href: styles },
  { rel: "preload", href: extraCss, as: "style" },
  { rel: "stylesheet", href: extraCss },
  { rel: "preload", href: fontawesomeStyles, as: "style" },
  { rel: "stylesheet", href: fontawesomeStyles },
  { rel: "preload", href: tooltipStyles, as: "style" },
  { rel: "stylesheet", href: tooltipStyles },
  { rel: "preload", href: toastStyles, as: "style" },
  { rel: "stylesheet", href: toastStyles },
  { rel: "preload", href: phoneInputStyles, as: "style" },
  { rel: "stylesheet", href: phoneInputStyles },
  { rel: "preload", href: datepickerStyles, as: "style" },
  { rel: "stylesheet", href: datepickerStyles },
  { rel: "preload", href: fontStyles, as: "style" },
  { rel: "stylesheet", href: fontStyles },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous"
  },
  {
    rel: "preconnect",
    href: "https://hive.inleo.io",
    crossOrigin: "anonymous"
  },
  {
    rel: "preconnect",
    href: "https://pagead2.googlesyndication.com",
    crossOrigin: "anonymous"
  }];

};

export default function App() {
  const {
    activeAccount,
    settings,
    theme,
    isMobile,
    pathname: pathname_ssr
  } = (useLoaderData() as RootData);

  const pathname_client = useLocation().pathname;
  const pathname = isSSR() ? pathname_ssr : pathname_client;

  // set app store with server data
  useFillStore({ activeAccount, settings });

  // set globals
  useRouteClientLoader(activeAccount);

  // handle route change events
  useHandleRouteChange();

  const { darkMode, dimMode, fontSizeClass, colorClass } = useMemo(() => {
    const userTheme = theme || settings?.theme || "Light";

    return {
      darkMode: userTheme === "Dark" || userTheme === "Dimmed",
      dimMode: userTheme === "Dark",

      fontSizeClass: settings ? `fs-${settings.typography}` : "",
      colorClass: settings ? settings.color.toLowerCase() : ""
    };
  }, [theme, settings]);

  const htmlClasses = useMemo(
    () =>
    cn(
      "min-h-screen text-sm sm:text-base flex flex-col",
      {
        dark: darkMode,
        dim: dimMode
      },
      fontSizeClass,
      colorClass
    ),
    [darkMode, dimMode, fontSizeClass, colorClass]
  );

  if (pathname === "/sitemap.txt") return <Outlet />;
  if (pathname === "/login" || pathname?.startsWith("/signup"))
  return (
    <AuthTemplate
      htmlClasses={htmlClasses}
      pathname={pathname}
      settings={settings} />);



  return (
    <html lang="en" className={htmlClasses} suppressHydrationWarning>
      <Head settings={settings} pathname={pathname} />

      <body className="flex-1 flex flex-col bg-pri dark:bg-pri-d" itemScope>
        {activeAccount &&
        <PrefetchPageLinks page={`/profile/${activeAccount?.name}`} />}
        

        <div
          className={cn("flex-1 flex w-full", {
            "pc:justify-center": !pathname?.startsWith("/publish"),
            "!pc:justify-start !max-w-[100vw]":
            pathname?.startsWith("/publish"),
            "justify-between":
            pathname !== "/posts" && (
            pathname?.startsWith("/posts") || pathname?.startsWith("/@"))
          })}
          suppressHydrationWarning>
          
          <AppHeader />
          <Sidebar
            loaderAccount={activeAccount}
            isMobile={isMobile}
            pathname={pathname} />
          
          <PublishButton />

          <main
            className={cn(
              "relative flex w-full pc:w-[68rem] pb-[55px] md:pb-20 pc:pb-0 sm:pl-[4.5rem] pc:pl-0",
              {
                "pc:w-screen": pathname?.startsWith("/publish"),
                "justify-center":
                pathname !== "/posts" && pathname?.startsWith("/posts"),
                "justify-start pc:w-8/12 pc:max-w-[calc(823px)]":
                pathname?.startsWith("/@")
              }
            )}
            suppressHydrationWarning>
            
            <Outlet />
          </main>

          <Announcements />
          <ToastContainer />
        </div>
        <LiveReload />
        <Scripts />
        <script async defer src="https://api.inleo.io/latest.js" />
        <noscript>
          <img
            src="https://api.inleo.io/noscript.gif"
            alt=""
            referrerPolicy="no-referrer-when-downgrade" />
          
        </noscript>
        <ScrollRestoration />
      </body>
    </html>);

}

export function ErrorBoundary() {
  return <CustomErrorBoundary />;
}

interface AuthTemplateProps {
  htmlClasses: string;
  settings: AccountSettings;
  pathname: string;
}

function AuthTemplate({ htmlClasses, settings, pathname }: AuthTemplateProps) {
  return (
    <html lang="en" className={htmlClasses}>
      <Head settings={settings} pathname={pathname} />

      <body className="flex-1 flex flex-col bg-pri dark:bg-pri-d">
        <Outlet />
        <LiveReload />
        <Scripts />
        <script async defer src="https://api.inleo.io/latest.js" />
        <noscript>
          <img
            src="https://api.inleo.io/noscript.gif"
            alt=""
            referrerPolicy="no-referrer-when-downgrade" />
          
        </noscript>
        <ToastContainer />
        <ScrollRestoration />
      </body>
    </html>);

}