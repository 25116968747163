import { useEffect } from "react";
import { useLocation, useNavigation } from "@remix-run/react";
import nProgress from "nprogress";
import { route } from "~/utils/route";
import { useAppStore } from "~/store";

export function useHandleRouteChange() {
  const location = useLocation();
  const navigation = useNavigation();

  const clearCache = useAppStore(store => store.threads.clearCache);

  // set previous route
  useEffect(() => {
    if (location.pathname) {
      route.previous = location.pathname;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname.startsWith("/threads") &&
      !location.pathname.includes("view") &&
      !route.previous?.startsWith("/threads")
    ) {
      clearCache();
    }
  }, [location.pathname]);

  // nprogress
  useEffect(() => {
    if (typeof window === "undefined") return;

    if (navigation.state === "loading") {
      nProgress.start();
    } else {
      nProgress.done();
    }
  }, [navigation]);
}
