import { useState, useEffect } from "react";
import {
  calculateRCMana,
  calculateVPMana,
  callHiveClient,
  fetchAccount,
  getTribeAccountInfo,
  tribeVotingPower,
  LEO_TOKEN_CONFIG,
  calcVotingPower
} from "~/utils/hive";
import CircularProgress from "./CircularProgress";
import { useAppStore } from "~/store";
import type { ExtendedAccount } from "@hiveio/dhive";
import type { RCAccount } from "@hiveio/dhive/lib/chain/rc";

export default function AccountManaBars() {
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const isDarkMode = useAppStore(store => store.settings.dark);

  const [accountData, setAccountData] = useState<ExtendedAccount | undefined>(
    undefined
  );
  const [rcAccount, setRCAccount] = useState<RCAccount[] | undefined>(
    undefined
  );
  const [tribeAccount, setTribeAccount] = useState(undefined);

  const [votingPower, setVotingPower] = useState<number>(0);

  useEffect(() => {
    if (!activeAccount) return;

    void (async function () {
      const account = await fetchAccount(activeAccount?.name);
      const rcApi = await callHiveClient("rc_api", "find_rc_accounts", {
        accounts: [activeAccount.name]
      });
      const engineAccount = await getTribeAccountInfo(activeAccount?.name);
      setAccountData(account);
      setRCAccount(rcApi);
      setTribeAccount(engineAccount);
    })();
  }, []);

  useEffect(() => {
    if (!accountData) return;

    void (async function () {
      const votingPower = await calcVotingPower(accountData);
      if (!votingPower) return;
      setVotingPower(votingPower.percentage / 100);
    })();
  }, [accountData]);

  const rcPower =
    rcAccount !== undefined &&
    calculateRCMana(rcAccount?.rc_accounts[0])?.percentage / 100;
  const leoVotingPower =
    accountData !== undefined &&
    tribeVotingPower(tribeAccount!, LEO_TOKEN_CONFIG).toPrecision(4);

  return (
    <div className="flex flex-col items-center justify-between gap-2 w-full">
      <div className="flex flex-wrap items-center justify-between gap-2 w-full">
        <div className="group flex flex-1 justify-center items-center gap-x-1.5 cursor-zoom-in">
          <CircularProgress
            size={34}
            progress={votingPower || 0}
            trackWidth={2}
            indicatorWidth={2}
            trackColor={isDarkMode ? "#1f2937" : "#e5e7eb"}
            indicatorColor={"#e31337"}
            label={Number(votingPower || 0)
              .toFixed(0)
              .toString()}
            labelClassName="text-xxs font-medium"
            className="group-hover:scale-[1.25] transition-transform duration-150"
          />

          <small className="text-xxs font-medium text-pri/50 dark:text-pri-d/60 uppercase">
            HIVE
          </small>
        </div>

        <div className="group flex flex-1 justify-center items-center gap-x-1.5 cursor-zoom-in">
          <CircularProgress
            size={34}
            progress={+Number(leoVotingPower || 0).toFixed(0)}
            trackWidth={2}
            indicatorWidth={2}
            trackColor={isDarkMode ? "#1f2937" : "#e5e7eb"}
            indicatorColor={"rgb(252, 188, 43)"}
            label={Number(leoVotingPower || 0)
              .toFixed(0)
              .toString()}
            labelClassName="text-xxs font-medium"
            className="group-hover:scale-[1.25] transition-transform duration-150"
          />

          <small className="text-xxs font-medium text-pri/50 dark:text-pri-d/60 uppercase">
            LEO
          </small>
        </div>

        <div className="group flex flex-1 justify-center items-center gap-x-1.5 cursor-zoom-in">
          <CircularProgress
            size={34}
            progress={rcPower || 0}
            trackWidth={2}
            indicatorWidth={2}
            trackColor={isDarkMode ? "#1f2937" : "#e5e7eb"}
            indicatorColor={"#06b6d4"}
            label={Number(rcPower || 0)
              .toFixed(0)
              .toString()}
            labelClassName="text-xxs font-medium"
            className="group-hover:scale-[1.25] transition-transform duration-150"
          />

          <small className="text-xxs font-medium text-pri/50 dark:text-pri-d/60 uppercase">
            RC
          </small>
        </div>
      </div>
    </div>
  );
}

