import {
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole
} from "@floating-ui/react";
import { Fragment, useEffect, useState } from "react";
import {
  faAward,
  faChartPie,
  faCog,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { useOnRouteChange } from "~/hooks/useOnRouteChange";
import { NavItemLink } from "./RootComponents";

export default function MoreDropdown({
  children,
  onFocusChange,
  align = "right"
}: {
  children: React.ReactNode;
  onFocusChange?: (focused: boolean) => void;
  align?: "top" | "right";
}) {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: open => setOpen(open),
    middleware: [],
    placement: "top-start",
    strategy: "fixed"
  });

  const click = useClick(context, {
    event: "click"
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss
  ]);

  useEffect(() => {
    onFocusChange?.(open);
  }, [open]);

  useOnRouteChange(() => {
    setOpen(false);
  });

  return (
    <Fragment>
      <div
        ref={reference}
        {...getReferenceProps}
        onClick={() => setOpen(current => !current)}
      >
        {children}
      </div>
      <FloatingPortal>
        {open && (
          <div
            className="hidden sm:flex flex-col justify-start items-start bg-pri dark:bg-pri-d border border-pri dark:border-pri-d rounded-xl px-3 py-1.5 pc:py-1.5 pc:px-1.5 text-xs z-[1000] overflow-hidden drop-shadow-md min-w-[200px] shadow-[0_0_12px_3px_rgb(255_255_255_/_17%)]"
            ref={floating}
            style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
            {...getFloatingProps()}
            tabIndex={-1}
          >
            <NavItemLink
              icon={faChartPie}
              to="/dashboard"
              className="flex-1 w-full !py-2.5 !mr-0 !rounded-lg outline-none"
            >
              <span className="flex flex-1 items-center gap-x-3">
                <span>Dashboard</span>
                <span className="flex w-fit py-0.5 px-3 rounded-full bg-acc/10 font-semibold text-acc text-xxs animate-pulse">
                  ✨ NEW
                </span>
              </span>
            </NavItemLink>

            {
              <NavItemLink
                icon={faAward}
                to="/leaderboard"
                className="flex-1 w-full !py-2.5 !mr-0 !rounded-lg outline-none"
              >
                Leaderboard
              </NavItemLink>
            }

            <NavItemLink
              to="/lists"
              icon={faFileLines}
              className="flex-1 w-full !py-2.5 !mr-0 !rounded-lg outline-none"
            >
              Lists
            </NavItemLink>
            <NavItemLink
              to="https://leodex.io"
              target="_blank"
              className="flex-1 w-full !py-2.5  !mr-0 !rounded-lg outline-none"
            >
              <div className="flex items-center gap-x-[22px] w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1000 1000"
                  width="22.5"
                  height="22.5"
                  className="-ml-[3px]"
                >
                  <polygon
                    fill="#A1A1AA"
                    points="758.66 303.94 700.99 385.34 582.33 455.39 601.62 570.2 500.05 639.55 398.48 570.2 417.76 455.39 299.1 385.34 241.41 303.94 447.36 369.02 425.03 535.52 500.05 564.73 575.06 535.52 552.74 369.02 758.66 303.94"
                  />
                  <polygon
                    fill="#A1A1AA"
                    points="500.05 639.55 438.99 673.19 298.92 570.2 222.9 297.24 204.3 289.64 165.67 519.67 500.05 790.36 834.39 519.67 795.74 289.64 777.19 297.24 701.11 570.2 561.09 673.19 500.05 639.55"
                  />
                </svg>
                <span className="flex flex-1">LeoDex</span>
              </div>
            </NavItemLink>
            <NavItemLink
              to="/referral"
              icon={faUserPlus}
              className="flex-1 w-full !py-2.5 !mr-0 !rounded-lg outline-none0"
            >
              <span className="flex flex-1 items-center gap-x-3">
                <span>Referral</span>
                <span className="flex w-fit py-0.5 px-3 rounded-full bg-acc/10 font-semibold text-acc text-xxs animate-pulse">
                  ✨ NEW
                </span>
              </span>
            </NavItemLink>
            <NavItemLink
              to="/settings"
              icon={faCog}
              className="flex-1 w-full !py-2.5 !mr-0 !rounded-lg outline-none"
            >
              Settings
            </NavItemLink>
          </div>
        )}
      </FloatingPortal>
    </Fragment>
  );
}

