import { Link } from "@remix-run/react";

interface Props {
  communityNameForPublish?: string;
  setIsOpen: (isOpen: boolean) => void;
}

export default function PublishDropdownButtonContent({
  communityNameForPublish
}: Props) {
  const linkClass =
    "flex flex-1 w-full py-3 px-4 text-sm rounded-lg text-left font-medium hover:bg-pri-d/10 dark:hover:bg-pri/10";

  return (
    <div className="flex flex-col w-full">
      <Link
        prefetch="intent"
        to={`/publish${
          communityNameForPublish ? `?community=${communityNameForPublish}` : ""
        }`}
        className={linkClass}
      >
        Create a Post
      </Link>

      <Link prefetch="intent" to="/publish/drafts" className={linkClass}>
        View Drafts
      </Link>

      <Link prefetch="intent" to="/publish/templates" className={linkClass}>
        View Templates
      </Link>

      <Link prefetch="intent" to="/publish/scheduling" className={linkClass}>
        View Scheduled Posts
      </Link>
    </div>
  );
}

