import React, { useState, useEffect } from "react";
import {
  Link,
  Links,
  Meta,
  Scripts,
  isRouteErrorResponse,
  useLocation,
  useNavigate,
  useRouteError
} from "@remix-run/react";
import classNames from "classnames";
import Logo from "./Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import type { AccountSettings } from "~/store/settings";
import { useAppStore } from "~/store";

export default function CustomErrorBoundary() {
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const error = useRouteError();
  const navigate = useNavigate();
  const location = useLocation();

  const [appLoaded, setAppLoaded] = useState(false);
  const [settings, setSettings] = useState<AccountSettings | null | undefined>(
    null
  );

  useEffect(() => {
    if (!window?.localStorage) return;

    try {
      const settings = window.localStorage.getItem("account-settings");
      const parsed = settings ? JSON.parse(settings) : undefined;

      setSettings(parsed);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (settings !== null) {
      setAppLoaded(true);
    }

    if (settings) {
      const html = document.getElementsByTagName("html")[0];
      html.classList.add(`fs-${settings.typography}`);
      html.classList.add(settings.color.toLowerCase());
    }
  }, [settings]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (window.location.hostname === "localhost") return;

    void (async function () {
      const account = activeAccount ? `@${activeAccount?.name}` : "Anonymous";
      const webhook_url =
        "https://discord.com/api/webhooks/1207372542445096960/fDoGN6WTC80Kk0mgNZS5ixILQTGrCMha2mnJbfSGMxtCG0qsLVcJbspKI2kWE-JVEFPs";

      const content =
        account +
        " encountered an error\n" +
        "```js\nError\n- url: " +
        location.pathname +
        ",\n- message: " +
        error?.message +
        ",\n- stack: " +
        error?.stack +
        "```";

      await fetch(webhook_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          content: content
        })
      });
    })();
  }, []);

  return (
    <html
      lang="en"
      className={classNames(
        "min-h-screen text-xs sm:text-base flex flex-col overflow-y-scroll opacity-0 transition-opacity duration-150",
        `fs-${settings?.typography} ${settings?.color?.toLowerCase()}`,
        {
          dark: settings?.theme === "Dark" || settings?.theme === "Dimmed",
          dim: settings?.theme === "Dark",
          "opacity-100": appLoaded
        }
      )}
    >
      <head>
        <title>InLeo | Microblogging in Blockchain</title>
        <Meta />
        <Links />
      </head>
      <body className="w-full min-h-screen flex-1 flex flex-col bg-pri dark:bg-pri-d">
        <div className="flex flex-1 flex-col items-center">
          <div className="flex flex-1 flex-col w-full max-w-[920px] gap-y-6 py-12 px-6 mx-auto">
            <button
              type="button"
              className="flex justify-center items-center w-9 h-9 -ml-1 rounded-full border border-pri dark:border-pri-d text-pri/60 dark:text-pri-d/60 hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] hover:text-pri dark:text-pri-d transition-colors duration-150"
              onClick={() => navigate(-1)}
              aria-label="Go Back"
              title="Go Back"
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" fixedWidth />
            </button>

            <div className="flex justify-between items-center w-full mb-4 gap-x-5">
              <div className="max-w-48">
                <Logo />
              </div>

              <nav className="flex items-center gap-x-4">
                <Link
                  to="/threads"
                  className="font-medium text-gray-500 dark:text-zinc-400 hover:text-pri dark:hover:text-pri-d transition-colors duration-150"
                >
                  Threads
                </Link>
                <Link
                  to="/posts"
                  className="font-medium text-gray-500 dark:text-zinc-400 hover:text-pri dark:hover:text-pri-d transition-colors duration-150"
                >
                  Articles
                </Link>
                <Link
                  to="/communities"
                  className="font-medium text-gray-500 dark:text-zinc-400 hover:text-pri dark:hover:text-pri-d transition-colors duration-150"
                >
                  Communities
                </Link>
              </nav>
            </div>

            <h1 className="text-3xl text-pri dark:text-pri-d font-bold mb-2">
              An error occurred
            </h1>

            <div className="relative w-full flex flex-col border border-pri dark:border-pri-d p-4 rounded-xl bg-pri-d/[.0425] dark:bg-pri/[.0425] sm:max-w-2/3 mx-auto overflow-auto">
              <code className="leading-normal text-xs text-pri/70 dark:text-pri-d/70 font-mono">
                {error instanceof Error ? (
                  <React.Fragment>
                    <h1>Error</h1>
                    <p>{error.message}</p>
                    <p>The stack trace is:</p>
                    <pre>{error.stack}</pre>
                  </React.Fragment>
                ) : isRouteErrorResponse(error) ? (
                  <React.Fragment>
                    <h1>Error</h1>
                    <h2>
                      {error.status} {error.statusText}
                    </h2>
                    <p>{error.data}</p>
                  </React.Fragment>
                ) : (
                  "Unknown Error"
                )}
              </code>
            </div>

            <div className="flex flex-col justify-center items-center text-center gap-y-1 mt-2">
              <strong className="text-center text-lg font-semibold mb-0.5">
                We have already started to fix it. 🛠️
              </strong>
              <small className="text-center text-sm text-pri/60 dark:text-pri-d/60 max-w-[436px] leading-snug px-4 mx-auto">
                The error that you've encountered is automatically sent to our
                developer team. We will fix the issue as soon as possible.
              </small>

              <div className="flex items-center justify-center gap-x-2 mt-5">
                <button
                  type="button"
                  className="py-2.5 px-5 bg-transparent text-pri dark:text-pri-d rounded-xl text-sm font-medium border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
                <button
                  type="button"
                  className="flex items-center gap-x-2 py-2.5 pl-6 pr-7 bg-gray-200 dark:bg-zinc-700 text-pri dark:text-pri-d rounded-xl text-sm font-medium hover:opacity-80 transition-opacity duration-150"
                  onClick={() => navigate(0)}
                >
                  <FontAwesomeIcon icon={faRotateRight} size="sm" />
                  <span>Retry</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <Scripts />
      </body>
    </html>
  );
}
