import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "@remix-run/react";
import { useEffect, useState } from "react";
import { type HiveContent, fetchPosts } from "~/utils/hive";

export function Announcements() {
  const navigate = useNavigate();

  const [announcement, setAnnouncement] = useState<HiveContent[] | false>(
    false
  );
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    void (async function () {
      let announcement = await fetchPosts("leofinance", 1, null, null);

      setAnnouncement(announcement);

      const announcementFromStorage =
        window.localStorage.getItem("announcement_id");
      const announcementReaded = window.localStorage.getItem(
        "announcement_readed"
      );

      // check if same announcement
      if (
        announcementFromStorage &&
        announcement[0].permlink === announcementFromStorage &&
        announcementReaded === "1"
      )
        return;

      setVisible(true);
      window.localStorage.setItem("announcement_id", announcement[0].permlink);
    })();
  }, []);

  // handle announcement visibility
  const closeAnnouncement = (redirect: boolean) => {
    window.localStorage.setItem("announcement_readed", "1");
    setVisible(false);

    if (!announcement) return;

    if (redirect) {
      navigate(`/@${announcement[0].author}/${announcement[0].permlink}`);
    }
  };

  if (!announcement || !visible) return null;
  return (
    <div className="fixed bottom-24 left-4 pc:bottom-6 !z-[9999] pc:right-6 pc:left-[initial] flex flex-col max-w-[340px] p-5 bg-gray-100 dark:bg-zinc-800 border border-pri dark:border-pri-d rounded-lg">
      <div className="flex w-full justify-between items-center gap-x-5 pb-3">
        <strong className="text-lg font-semibold">
          New LEO Announcement 🎉
        </strong>
        <button
          className="flex items-center justify-center w-8 h-8 text-sm rounded-full hover:bg-gray-200 dark:hover:bg-zinc-700 transition-colors duration-150"
          onClick={() => closeAnnouncement(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <div  className="flex flex-col  gap-y-4">
        <p className="line-clamp-3  text-sm text-gray-500 dark:text-zinc-400">
          {announcement[0].title}
        </p>

        <div className="flex flex-row items-center gap-x-1">
          <button
            type="button"
            className="py-2 px-4 text-xs font-medium bg-acc text-pri rounded-full hover:bg-acc-hov transition-colors duration-150"
            onClick={() => closeAnnouncement(true)}
          >
            Read More
          </button>
          <button
            type="button"
            className="py-2 px-4 text-xs font-medium border border-acc text-acc rounded-full hover:bg-acc-hov hover:border-acc-hov hover:text-pri transition-colors duration-150"
            onClick={() => closeAnnouncement(false)}
          >
            Got It!
          </button>
        </div>
      </div>
    </div>
  );
}
