import { Links, Meta } from "@remix-run/react";
import { useEffect, useMemo } from "react";
import { isSSR } from "~/utils/ssr";
import type { AccountSettings } from "~/store/settings";

export function Head({
  pathname,
  settings
}: {
  pathname: string;
  settings: AccountSettings | undefined;
}) {
  const { isAuthPage, isClaimKeysPage } = useMemo(() => {
    return {
      isAuthPage: pathname === "/login" || pathname.startsWith("/signup"),
      isClaimKeysPage: pathname.startsWith("/claim-keys")
    };
  }, [pathname]);

  useEffect(() => {
    const handleLoad = () => {
      {
        const script = document.createElement("script");
        script.src = "https://api.inleo.io/latest.js";
        script.async = true;
        script.defer = true;

        // Append the script to the document body
        document.body.appendChild(script);
      }
      // Create the script element only after the page has fully loaded
    };

    setTimeout(
      () => {
        if (!isSSR() && document.readyState !== "loading") {
          handleLoad();
        }
      },
      isAuthPage || isClaimKeysPage ? 1 : 2500
    );
  }, []);

  return (
    <head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=yes,viewport-fit=cover"
      />
      <meta
        name="theme-color"
        content={settings?.theme === "Dark" ? "#000" : "#fff"}
      />
      <meta
        name="description"
        content="InLeo is a blockchain-based social media community for Crypto & Finance content creators. Our tokenized blogging platform allows users and creators to engage and share content on the blockchain while earning LEO token rewards."
      />
      <title>InLeo | Microblogging in Blockchain</title>

      <Meta />
      <Links />
    </head>
  );
}
